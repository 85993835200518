import React from 'react'
import { graphql } from 'gatsby'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Views
import PressReleaseIndex from '../components/views/PressReleaseIndex/PressReleaseIndex'

const PressReleaseIndexTemplate = ({ data, pageContext, location }) => {
  return (
    <>
      <Seo
        title={data.page.data.title.text}
        customTitle={data.page.data.meta_title}
        description={data.page.data.meta_description.text}
        image={data.page.data.social_image.url}
        slug={location.pathname}
      />
      <PressReleaseIndex
        data={data}
        pageContext={pageContext}
      />
    </>
  )
}

export default PressReleaseIndexTemplate

export const PageQuery = graphql`
  query PressReleaseIndexTemplateQuery ($skip: Int!, $limit: Int!, $categoryUid: String) {
    page: prismicPage (
      uid: {
        eq: "press-and-media"
      }
    ) {
      uid
      data {
        title {
          text
        }
        meta_title
        meta_description {
          text
        }
        meta_fields {
          metafield_key
          metafield_value {
            html
            text
          }
          metafield_image {
            ...Image
          }
        }
        social_image {
          url
        }
      }
      ...HeroMinimal
      ...FiftyFifty
      ...PreFooter
    }
    pressReleases: allPrismicPressRelease (
      filter: {
        data: {
          featured: {
            eq: false
          },
          categories: {
            elemMatch: {
              category: {
                uid: {
                  glob: $categoryUid
                }
              }
            }
          }
        }
      },
      sort: {
        order: DESC,
        fields: data___date
      },
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...PressRelease
      }
      totalCount
    }
    featuredPressReleases: allPrismicPressRelease (
      filter: {
        data: {
          featured: {
            eq: true
          },
          categories: {
            elemMatch: {
              category: {
                uid: {
                  glob: $categoryUid
                }
              }
            }
          }
        }
      },
      limit: 1,
      skip: $skip,
      sort: {
        order: DESC,
        fields: data___date
      }
    ) {
      nodes {
        ...PressRelease
      }
    }
    pressReleaseCategories: allPrismicPressReleaseCategory (
      sort: {
        fields: data___title___text,
        order: ASC
      }
    ) {
      nodes {
        id
        uid
        url
        data {
          title {
            text
          }
        }
      }
    }
    pressHighlights: allPrismicPressHighlight (
      sort: {
        fields: data___date,
        order: DESC
      },
      limit: 4
    ) {
      nodes {
        id
        data {
          title {
            text
          }
          publication {
            text
          }
          link {
            ...Link
          }
          date(formatString: "D MMMM YYYY")
        }
      }
    }
  }
`
