import React from 'react'
import PropTypes from 'prop-types'

// Content Components
import { RenderPositionContentComponents } from '../../elements/PageElements'

// Generic
import Container from '../../shared/Container/Container'
import ArticleGrid from '../../shared/ArticleGrid/ArticleGrid'
import ArticleGridFeaturedItem from '../../shared/ArticleGrid/ArticleGridFeaturedItem'
import Pagination from '../../shared/Pagination/Pagination'
import Image from '../../shared/Image/Image'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import PressHighlights from '../../shared/PressHighlights/PressHighlights'

// Semi-Local
import BlogArticleIndexContentHeader from '../../shared/BlogArticle/BlogArticleIndexContentHeader'
import BlogArticleIndexCategorySelect from '../../shared/BlogArticle/BlogArticleIndexCategorySelect'

// Utils
import pressReleaseDecorator from '../../../utils/pressReleaseDecorator'

const CaseStudyTestimonialIndex = ({ data, pageContext }) => {
  // Take additional meta data and add it to the FiftyFifty component
  // Find the FiftyFifty component
  const fiftyFiftyIndex = data.page.data.body.findIndex((findSlice) => {
    return findSlice.slice_type === 'fifty_fifty'
  })

  // If it exists...
  if (fiftyFiftyIndex > -1) {
    const additionalBodyContentElements = []

    // Get the `contact body` content, and render it as HtmlContent
    const pressContactBody = data.page.data.meta_fields.find(metafield => metafield.metafield_key === 'press_contact_body')
    if (pressContactBody) {
      additionalBodyContentElements.push(
        <HtmlContent
          key={1}
          html={pressContactBody.metafield_value.html}
          className='text-lg'
        />
      )
    }

    // Get the `contact contact` content, and render that too
    const pressContactContact = data.page.data.meta_fields.find(metafield => metafield.metafield_key === 'press_contact_contact')
    if (pressContactContact) {
      additionalBodyContentElements.push(
        <div
          key={2}
          className='flex items-center space-x-3 mt-6'
        >
          {pressContactContact.metafield_image.url && (
            <div className='h-12 flex-grow-0 flex-shrink-0'>
              <Image
                fallbackAlt={pressContactContact.metafield_value.text}
                className='h-full w-12'
                {...pressContactContact.metafield_image}
              />
            </div>
          )}
          <HtmlContent
            html={pressContactContact.metafield_value.html}
            className='c-prose c-prose--header-primary'
          />
        </div>
      )
    }

    // Actually add it to the data so it can be rendered
    data.page.data.body[fiftyFiftyIndex].primary.additionalBodyContent = additionalBodyContentElements
  }

  return (
    <>
      <RenderPositionContentComponents
        slices={data.page.data.body}
        validComponents={['hero_minimal', 'fifty_fifty']}
      />
      <section id='content'>
        <Container className='mb-24'>
          <BlogArticleIndexContentHeader>
            <h2>In the press</h2>
            <BlogArticleIndexCategorySelect
              categories={data.pressReleaseCategories.nodes}
              currentCategory={pageContext.categoryUid}
              taxonomy='press_release'
              categoryTaxonomy='press_release_category'
            />
          </BlogArticleIndexContentHeader>
          <div className='mb-16'>
            {data.featuredPressReleases.nodes.length > 0 && (
              <ArticleGridFeaturedItem
                body={data.featuredPressReleases.nodes[0].data.intro.text}
                category='Featured'
                className='mb-6'
                media={(
                  <Image
                    fallbackAlt={data.featuredPressReleases.nodes[0].data.title.text}
                    className='h-full object-cover'
                    {...data.featuredPressReleases.nodes[0].data.featured_image}
                  />
                )}
                title={data.featuredPressReleases.nodes[0].data.title.text}
                url={data.featuredPressReleases.nodes[0].url}
              />
            )}
            <ArticleGrid articles={pressReleaseDecorator(data.pressReleases.nodes)} />
          </div>
          <Pagination
            taxonomy={pageContext.categoryUid !== '*' ? 'press_release_category' : 'press_release'}
            totalCount={data.pressReleases.totalCount}
            pageContext={pageContext}
          />
        </Container>
        <PressHighlights
          title='Highlights'
          rows={data.pressHighlights.nodes}
          ctaUrl='/press-and-media/highlights'
        />
      </section>
      <RenderPositionContentComponents
        slices={data.page.data.body}
        validComponents={['pre-footer']}
      />
    </>
  )
}

CaseStudyTestimonialIndex.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default CaseStudyTestimonialIndex
